import { Stat } from '../../stat';
import { MonthlyLineChartProps, MonthlyLineChart } from '../monthly-line-chart';
import {
  CompanySentimentMetric,
  getAggregatedCompetitorSentiment,
} from '../utils/get-aggregated-competitor-metrics';
import { CompanyStatsProps } from '../types';
import { calculatePercentageChange } from '../utils/calculate-percentage-change';
import { Text } from '@chakra-ui/react';

interface BusinessOutlookChartProps {
  sentimentData: CompanyStatsProps['sentimentData'];
  selectedCompanyRCID: CompanyStatsProps['selectedCompanyRCID'];
  competitors: CompanyStatsProps['competitors'];
  companyName: string | null | undefined;
}
export const BusinessOutlookChart = ({
  selectedCompanyRCID,
  companyName,
  competitors,
  sentimentData,
}: BusinessOutlookChartProps) => {
  const primarySentimentData = sentimentData?.sentiment2d?.find(
    (datum) => datum?.metadata?.id === selectedCompanyRCID
  );
  const primaryCompanyCategories = primarySentimentData?.category || [];

  const outlookTimeseries: MonthlyLineChartProps['timeSeries'] =
    primaryCompanyCategories?.map((datum) => ({
      id: datum?.metadata?.id,
      value: datum?.metrics?.businessOutlookRating,
      date: datum?.metadata?.shortName,
      count: datum?.metrics?.businessOutlookRatingCount,
    })) || [];

  const { lastValue, percentageChange } =
    calculatePercentageChange(outlookTimeseries);

  const aggregatedCompetitorOutlook = sentimentData?.sentiment2d
    ? getAggregatedCompetitorSentiment(
        competitors,
        sentimentData.sentiment2d,
        CompanySentimentMetric.BusinessOutlookRating
      )
    : 0;

  const vsCompetitors = lastValue - aggregatedCompetitorOutlook;

  return (
    <Stat
      name={CompanySentimentMetric.BusinessOutlookRating}
      plotInfo={
        <Text color="white">
          The average score given by workers on the topic of Business Outlook
        </Text>
      }
      header="Business Outlook"
      statValues={{
        primaryValue: lastValue.toFixed(2),
        percentChange: percentageChange,
        vsCompetitors,
      }}
      competitorFormatting={(value: number) => value.toFixed(2)}
    >
      <MonthlyLineChart
        name={CompanySentimentMetric.BusinessOutlookRating}
        shortName={companyName}
        timeSeries={outlookTimeseries}
        chartProps={{
          ttType: 'percent',
          ttMainFormat: '.2f',
          ttSecondaryFormat: ',',
        }}
        type="Company"
      />
    </Stat>
  );
};
