import { graphql } from '@revelio/data-access';

export const GET_ENTITY_DATA = graphql(`
  query GetEntityData($filters: EntityFilters) {
    entity(filters: $filters) {
      summary {
        name
        description
        founded
        headquarters
      }
      competitors {
        metadata {
          id
          shortName
          longName
        }
        closeness_score
      }
    }
  }
`);

export const GET_COMPANY_SUMMARY_MAP_DATA = graphql(`
  query CompanySummaryMapData($query: TalentDiscoveryV1_5Filter) {
    talent_discovery_search_v1_5(query: $query) {
      map_data {
        id
        type
        count
        share
        lat
        lon
        location
      }
    }
  }
`);

export const GET_GEOGRAPHY_SUMMARY_MAP_DATA = graphql(`
  query GeographySummaryMapData($query: MapCoordsReq!) {
    mapCoords(req: $query) {
      region {
        id
        name
        lat
        lon
      }
      country {
        id
        name
        lat
        lon
      }
      msa {
        id
        name
        lat
        lon
      }
    }
  }
`);

export const GET_ROLE_SELECTION_LIST = graphql(`
  query RoleSelectionList {
    selectionList {
      job_category {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
      }
      role_k50 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
      }
      role_k150 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
      }
      role_k300 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
      }
      role_k500 {
        id
        shortName
        longName: shortName
        label: shortName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
      }
      role_k1000 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
        role_k500: roleK500
      }
      role_k1250 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
        role_k500: roleK500
        role_k1000: roleK1000
      }
      role_k1500 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
        role_k500: roleK500
        role_k1000: roleK1000
        role_k1250: roleK1250
      }
    }
  }
`);
