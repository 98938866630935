import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import { GetEntityDataQuery } from '@revelio/data-access';
import { Card } from '@revelio/layout';
import styles from '../company-summary/company-summary-overview.module.css';
import { useEffect, useRef, useState } from 'react';
import { PlotInfoComp } from '@revelio/composed';

type EntitySummary = NonNullable<
  NonNullable<GetEntityDataQuery['entity']>['summary']
>;

interface RoleSummaryOverviewProps {
  description: EntitySummary['description'];
  roleTitle: EntitySummary['name'];
  commonTitles?: string;
}

export const RoleSummaryOverview = ({
  description,
  roleTitle,
  commonTitles,
}: RoleSummaryOverviewProps) => {
  const factSize = '54px';
  const [fontSize, setFontSize] = useState(14);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const maxFontSize = 26;
    const minFontSize = 12;
    const charCount = description?.length || 1;

    const boostFactor = maxFontSize - charCount / 500;
    const decayFactor = Math.log(charCount) / 0.8;

    const newFontSize = Math.max(minFontSize, boostFactor - decayFactor);

    setFontSize(newFontSize);
  }, [description]);

  useEffect(() => {
    const handleScroll = () => {
      const element = descriptionRef.current;
      if (element) {
        const isScrolledToBottom =
          element.scrollHeight - element.scrollTop - element.clientHeight <= 1;
        setIsAtBottom(isScrolledToBottom);
      }
    };

    const checkOverflowOnLoad = () => {
      const element = descriptionRef.current;
      if (element) {
        setTimeout(() => {
          const hasOverflow = element.scrollHeight > element.clientHeight;
          if (hasOverflow) {
            setIsAtBottom(false);
          } else {
            setIsAtBottom(true);
          }
        }, 1000); // Delay to let the browser finish rendering and calculating the heights
      }
    };

    const element = descriptionRef.current;
    if (element) {
      checkOverflowOnLoad();
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [description]);

  return (
    <Card p="12px 20px">
      <Flex height="100%" flexDirection="column" justifyContent="space-between">
        <Box height={`calc(100% - ${factSize})`}>
          <Flex mb={3}>
            <Text fontWeight={600} fontSize={14}>
              {roleTitle}
            </Text>
            <PlotInfoComp
              plotInfoBody={
                <Text color="white">
                  Descriptions are sourced from AI generated text.
                </Text>
              }
              plotInfoConfig={{
                popoverPlacement: 'right',
                iconBoxSize: 2.5,
              }}
            />
          </Flex>
          <Text
            fontSize={fontSize}
            height="90%"
            overflowY="scroll"
            ref={descriptionRef}
            className={`${!isAtBottom ? styles.fadedScroll : undefined}`}
          >
            {description}
          </Text>
        </Box>

        <Box height={factSize}>
          <Divider my={4} />
          <Flex>
            <Text fontSize={14} fontWeight={600} mr={1} whiteSpace="nowrap">
              Common Titles:
            </Text>
            <Text
              fontSize={14}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              title={commonTitles || ''}
            >
              {commonTitles}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};
