import { Stat } from '../../stat';
import { MonthlyLineChart } from '../monthly-line-chart';

import { getMetricStats } from '../utils/get-headcount-growth-metrics';
import {
  CompanyCompositionStatsMetric,
  getAggregatedCompetitorCompositionMetrics,
} from '../utils/get-aggregated-competitor-metrics';
import { CompanyStatsProps } from '../types';
import { Text } from '@chakra-ui/react';

const percentise = (value: number) => `${(value * 100).toFixed(1)}%`;

interface GrowthChartProps {
  compositionData: CompanyStatsProps['compositionData'];
  selectedCompanyRCID: CompanyStatsProps['selectedCompanyRCID'];
  competitors: CompanyStatsProps['competitors'];
  companyName: string | null | undefined;
}
export const GrowthChart = ({
  compositionData,
  selectedCompanyRCID,
  companyName,
  competitors,
}: GrowthChartProps) => {
  const primaryCompanyCompositionData = compositionData?.composition?.find(
    (datum) => datum?.metadata?.id === selectedCompanyRCID
  );

  const { lastValue, percentageChange } = getMetricStats(
    primaryCompanyCompositionData?.metrics,
    CompanyCompositionStatsMetric.GrowthRate
  );

  const weightedCompetorHeadcount = getAggregatedCompetitorCompositionMetrics(
    competitors,
    compositionData,
    CompanyCompositionStatsMetric.GrowthRate
  );

  return (
    <Stat
      name={CompanyCompositionStatsMetric.GrowthRate}
      plotInfo={
        <Text color="white">
          The growth rate is the percent change in the total workforce. It is
          the difference between the hiring rate and attrition rate.
        </Text>
      }
      header="Growth"
      statValues={{
        primaryValue: percentise(lastValue),
        percentChange: percentageChange,
        vsCompetitors: (lastValue - weightedCompetorHeadcount) * 100,
      }}
      competitorFormatting={(value: number) => `${value.toFixed(1)}%`}
    >
      <MonthlyLineChart
        name={CompanyCompositionStatsMetric.GrowthRate}
        shortName={companyName}
        timeSeries={
          primaryCompanyCompositionData?.metrics?.growth_rate?.timeseries
        }
        chartProps={{
          ttPosition: 'left',
        }}
        type="Company"
      />
    </Stat>
  );
};
