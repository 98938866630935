export enum RoleCompositionStatsMetric {
  Headcount = 'headcount',
  GrowthRate = 'growth_rate',
}

export enum RolePostingsStatsMetric {
  ActivePostings = 'active',
}

export enum RoleSentimentMetric {
  OverallRating = 'overallRating',
  BusinessOutlookRating = 'businessOutlookRating',
  CompensationsBenefitsRating = 'compensationsBenefitsRating',
}
