import { RoleStatsProps } from '../types';
import { calculatePercentageChange } from '../../../../../company-summary/company-stats/company-stat/company-stat-line-charts/utils/calculate-percentage-change';
import { RolePostingsStatsMetric } from '../utils/get-aggregated-competitor-metrics';
import { Text } from '@chakra-ui/react';
import { formatSimpleInteger } from '../../../../../company-summary/company-stats/company-stat/company-stat-line-charts/utils/format-simple-integer';
import {
  DailyLineChart,
  DailyLineChartProps,
} from '../../../../../company-summary/company-stats/company-stat/company-stat-line-charts/daily-line-chart';
import { Stat } from '../../../../../company-summary/company-stats/company-stat/stat';

interface PostingsChartProps {
  postingsActiveData: RoleStatsProps['postingsActiveData'];
  selectedRoleId: RoleStatsProps['selectedRoleId'];
  roleName: string | null | undefined;
}
export const PostingsChart = ({
  postingsActiveData,
  selectedRoleId,
  roleName,
}: PostingsChartProps) => {
  const primaryPostingData = postingsActiveData?.posting?.find(
    (datum) => datum?.metadata?.id === selectedRoleId
  );

  const primaryRoleCategories = primaryPostingData?.category || [];

  const postingsTimeseries: DailyLineChartProps['timeSeries'] =
    primaryRoleCategories?.map((datum) => ({
      id: datum?.metadata?.id,
      value: datum?.metrics?.active,
      date: datum?.metadata?.shortName,
    })) || [];

  const { lastValue, percentageChange } =
    calculatePercentageChange(postingsTimeseries);

  const primaryValue = formatSimpleInteger(lastValue);

  return (
    <Stat
      name={RolePostingsStatsMetric.ActivePostings}
      plotInfo={<Text color="white">The number of active job postings.</Text>}
      header="Active Postings"
      statValues={{
        primaryValue,
        percentChange: percentageChange,
      }}
    >
      <DailyLineChart
        name={RolePostingsStatsMetric.ActivePostings}
        shortName={roleName}
        timeSeries={postingsTimeseries}
        type="Role"
      />
    </Stat>
  );
};
