import { useContext, useMemo } from 'react';
import { Maybe } from '@revelio/data-access';
import { LineChart } from '@revelio/d3';
import { ChartsRenderedContext } from '../../../charts-rendered-context';

export interface MonthlyLineChartProps {
  name: string;
  shortName: string | undefined | null;
  timeSeries:
    | ({
        id?: number | string | null | undefined;
        value?: Maybe<number>;
        date?: string | null | undefined;
        count?: Maybe<number>;
        share?: Maybe<number>;
      } | null)[]
    | null
    | undefined;
  chartProps?: {
    ttType?: string;
    ttMainFormat?: string;
    ttSecondaryFormat?: string;
    ttPosition?: 'left' | 'right';
  };
  type: 'Company' | 'Role';
}

export const MonthlyLineChart = ({
  name,
  timeSeries,
  shortName,
  chartProps,
  type,
}: MonthlyLineChartProps) => {
  const linePlotData = useMemo(
    () => [
      {
        id: 1,
        metadata: {
          shortName: shortName || name,
          longName: shortName,
          type,
        },
        value: timeSeries?.map((datum) => {
          const id = () => {
            if (typeof datum?.id === 'string') {
              return parseInt(datum?.id);
            }

            return datum?.id || 0;
          };

          return {
            id,
            metadata: {
              count: datum?.count,
              longName: datum?.date,
              month: datum?.date
                ? parseInt(datum?.date.split('-')[1] || '1')
                : 1,
              year: datum?.date
                ? parseInt(datum?.date.split('-')[0] || '2008')
                : 2008,
              share: datum?.share,
            },
            value: datum?.value,
          };
        }),
      },
    ],
    [shortName, name, timeSeries, type]
  );

  const lineColor = '#5BD992';

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'ChartComponent must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;
  if (!timeSeries?.length) {
    return null;
  }

  return (
    <LineChart
      name={name}
      lineColor={lineColor}
      areaFillColor={lineColor}
      hideAxis
      chartSize="small"
      metaValueCompany="shortName"
      dateFormat="YM"
      ttType="single"
      ttCustomString="days"
      ttMainFormat=".1%"
      ttSecondaryFormat=","
      data={linePlotData}
      setChartHasRendered={setChartHasRendered}
      leftFade
      {...chartProps}
    />
  );
};
