import { MonthlyLineChart } from '../../../../../company-summary/company-stats/company-stat/company-stat-line-charts/monthly-line-chart';

import { getMetricStats } from '../utils/get-headcount-growth-metrics';
import { RoleCompositionStatsMetric } from '../utils/get-aggregated-competitor-metrics';
import { RoleStatsProps } from '../types';
import { Text } from '@chakra-ui/react';
import { Stat } from '../../../../../company-summary/company-stats/company-stat/stat';

const percentise = (value: number) => `${(value * 100).toFixed(1)}%`;

interface GrowthChartProps {
  compositionData: RoleStatsProps['compositionData'];
  selectedRoleId: RoleStatsProps['selectedRoleId'];
  roleName: string | null | undefined;
}
export const GrowthChart = ({
  compositionData,
  selectedRoleId,
  roleName,
}: GrowthChartProps) => {
  const primaryRoleCompositionData = compositionData?.composition?.find(
    (datum) => datum?.metadata?.id === selectedRoleId
  );

  const { lastValue, percentageChange } = getMetricStats(
    primaryRoleCompositionData?.metrics,
    RoleCompositionStatsMetric.GrowthRate
  );

  return (
    <Stat
      name={RoleCompositionStatsMetric.GrowthRate}
      plotInfo={
        <Text color="white">
          The growth rate is the percent change in the total workforce. It is
          the difference between the hiring rate and attrition rate.
        </Text>
      }
      header="Growth"
      statValues={{
        primaryValue: percentise(lastValue),
        percentChange: percentageChange,
      }}
    >
      <MonthlyLineChart
        name={RoleCompositionStatsMetric.GrowthRate}
        shortName={roleName}
        timeSeries={
          primaryRoleCompositionData?.metrics?.growth_rate?.timeseries
        }
        chartProps={{
          ttPosition: 'left',
        }}
        type="Role"
      />
    </Stat>
  );
};
